import SubscribeForm from './SubscribeForm'

export function GetNewsAlerts() {
	return(
		<main className="main">
					<div style={{marginTop: '30px', padding: '20px', backgroundColor: '#ddd9d9', color: '#383838'}}>

					<h2>Subscribe for free</h2>
					<p>Stay up to date with the debt capital markets.</p>
					<p>DCM Insider keeps you informed of important deals and news in the market.</p>

					<SubscribeForm />

					</div>
		</main>
	)
}
