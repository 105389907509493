import React from 'react';
import {Link} from 'react-router-dom';
import RecentPosts from '../RecentPosts'
import SubscribeForm from './SubscribeForm'

function HomePage({user, db}){
	return (
		<div className="homepage-background">
		<main className="main homepg">
				<div style={{paddingTop: '0px', paddingBottom: '50px', paddingLeft: '0', paddingRight: '0'}} className="article">


		<div style={{padding: '2rem 0', background: "#4e6888"}}>
		<p style={{fontSize: '1.7em', maxWidth: '600px', margin: '0 2rem 2rem 2rem', color: 'white'}}>News for debt capital markets professionals. Subscribe for free.</p>
		<div style={{maxWidth: '408px', borderRadius: '5px', border: '3px solid #4e6888', margin: '0 2rem'}}>
	<SubscribeForm style={{background: 'blue', border: '3px solid blue'}} />
		</div>
		</div>
		<div style={{background: 'rgba(78, 104, 136, 0.2)', padding: '0.5rem 0', margin: '0px 0'}}>
		
		{/*
		<p style={{fontSize: '1.0em', maxWidth: '600px', margin: '1rem 2rem 1rem 2rem'}}>Sponsoring DCM insider is an efficient way to reach DCM investors, issuers, bankers and lawyers. <a href='/sponsor-enquiry' style={{fontSize: 'inherit', textDecoration: 'underline'}}>Ask about becoming a sponsor.</a></p>
		*/}
		</div>



<h2 style={{marginTop: '30px', marginBottom: '40px', paddingLeft: '2rem', paddingRight: '2rem'}}>Recent deals</h2>

		<RecentPosts db={db} />
		{/*
		{user ? (
			<p>Welcome, {user.email}! {!user.isPaidSubscriber && <Link to='/subscribe'>Subscribe</Link>}</p>) : (
				<p><Link to="login">Log in</Link> or <Link to="/subscribe">Subscribe</Link></p>
			)}
			*/}
		</div>
	</main>
		</div>
	);
}

export default HomePage;
