import SubscribeForm from './SubscribeForm'

export function SponsorEnquiry() {
	return(
		<main className="main">
					<div style={{marginTop: '30px', padding: '20px', backgroundColor: '#ddd9d9', color: '#383838'}}>

					<h2>Sponsor DCM Insider</h2>
					<p>DCM Insider is read by over 20,000 debt capital markets professionals.</p>
					<p>Repeatedly get your message across to the people who will benefit from your offer.</p>
					<p>Contact us at sponsorship@dcminsider.com</p>

					{/* Add sponsor form */}
					</div>
		</main>
	)
}
